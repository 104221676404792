import React, { useState } from 'react';
import './BRLanding.css'
import { Link } from 'react-router-dom'
import PostFromPost from '../../Blogs/BlogsLanding/Accordion/BlogPosts/PostFromPost';


const BRLanding = () => {

  const isAuth = true; // Example value for authentication status
  const limit = 10; // Example value for limit

 

  return(
    <div data-section className="BRLanding" id="BRLanding">

        <div className="BRLanding-hero">
        <h3>Blogs</h3>
        </div>  

        <div className="BR_area_wrapper">
           
            <PostFromPost isAuth={true} limit={4} className="blogz"/>
        </div>

    </div>
  )

}

export default BRLanding