import React from 'react'
import './Content.css'
import 'bootstrap/dist/css/bootstrap.css'; // Import Bootstrap CSS


const Content = () => {
  return (
    <div data-section className="content" id="articles">
      
    <div className="row">
      <div className="col col-4 " id="top-image">
        <img src="Assets/IG_Live_interviews.png" alt="IG_Live_interviews image" />
      </div>

      <div className="col col-8 " id="top-text">
        <img src="Assets/Socials/Instagram_W.png" alt="instagram logo"/>
        <h3>@10minutes_withthestar</h3>
        <a href="https://instagram.com/10minutes_withthestar?igshid=OGQ5ZDc2ODk2ZA=="><p>Click here to catch us live on Instagram!</p></a>
      </div>


      <div className="col col-8 " id="bottom-text">

        <div className="magz-promo">
          <img src="Assets/Socials/Instagram_BW.png" alt="instagram logo"/> 
          <h3>@10minutes_withthestar_magazine</h3>
        </div>

        <p>To stay in the loop and up to date with your favourite artists connect with us on: 
          <br/>
          <a href="https://instagram.com/10minutes_withthestar_magazine?igshid=OGQ5ZDc2ODk2ZA==">@10minutes_withthestar_magazine</a>
        </p>

      </div>
      <div className="col col-4 " id="bottom-image">
        <img src="Assets/MagCovers/MAGCover-EGG-ALPHISIPHO.jpg" alt="Mag_IG_PAGE image" />
      </div>
    </div>

    </div>
  )
}

export default Content