import React, { useState, useEffect } from 'react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { auth, db, storage } from '../firebase-config'; // Import your Firebase configuration
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify'; // Import DOMPurify for HTML sanitization
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Navbar from '../../Header/Navbar/Nav'
import './Createblog.css'
import BottomFooter from '../../Home/Footer/BottomFooter';
// import RTI from '../RichTextEditor/RTI'

const Createblog = ({ isAuth }) => {
  const [title, setTitle] = useState('');
  const [postText, setPostText] = useState('');
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(''); // State to store the image URL
  const sanitizedHtml = DOMPurify.sanitize(postText);
  const postCollectionRef = collection(db, 'posts');
  const storageRef = ref(storage, 'images'); // Check if this path exists in your Firebase Storage
  const [loading, setLoading] = useState(false); // Added loading state

  let navigate = useNavigate();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);

        // Create a temporary URL for the selected image
        const tempImageUrl = URL.createObjectURL(file);
        setImageUrl(tempImageUrl);
  };

  const uploadImage = async () => {
    if (image) {
      const imageRef = ref(storageRef, image.name);
      await uploadBytes(imageRef, image);
      const downloadURL = await getDownloadURL(imageRef);
      setImageUrl(downloadURL); // Set the image URL in the state

      // Log the image URL to the console
    console.log('Image URL:', downloadURL);

      return downloadURL;
    }
    return null;
  };



  const createPost = async () => {
    setLoading(true); // Set loading to true just before making asynchronous calls

    try {
      const imageUrl = await uploadImage();

      await addDoc(postCollectionRef, {
        title,
        postHtml: sanitizedHtml,
        postText,
        imageUrl,
        author: {
          name: auth.currentUser.displayName,
          id: auth.currentUser.uid,
        },
        timestamp: serverTimestamp(),
      });

      navigate('/Blogs');
    } catch (error) {
      console.error('Error creating post:', error);
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  // const isSubmitDisabled = !title.trim() || !postText.trim(); // Disable if title or post is empty
  const isSubmitDisabled = !title.trim() || !postText.trim() || !image; // Disable if title, post, or image is empty


  useEffect(() => {
    if (!isAuth) {
      navigate('/Bloglogin');
    }
  }, []);

  return (
    <div className="createblog-area">
      <Navbar/>
      <div className="cb-Container">
          {/* <h3>Create a Post</h3> */}

          <div className="inputGroup title">
              <label>Title</label>
              <input placeholder="Write your blog title here..." 
                  onChange={(event) =>{
                  setTitle(event.target.value)
              }}/>


                <button
                  onClick={createPost}
                  disabled={isSubmitDisabled || loading} // Disable the button if form is invalid or loading is true
                  style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
                >
                  {loading ? 'Creating Post...' : 'Submit post'}
                </button>



          </div>

          {/* this is where we need to recode for reach text editor  */}
          <div className="inputGroup post">
          {/* <label>Post:</label> */}
              
           {/*    <RTI  onContentChange={onContentChange} onContentChange={setPostText} onImageUpload={onImageUpload} onChange={handleImageChange} />  */}


              <div className="uploadimagearea">
                <label>Upload image</label>
                <input type="file" onChange={handleImageChange}/>


                {imageUrl && (
                  <div className="imageUpload">
                    {/* <p>Uploaded Image:</p> */}
                    <img src={imageUrl} alt="Uploaded"/>
                  </div>
                )}
               </div>
               

                <textarea
                  placeholder="Post..."
                  onChange={(event) => {
                    setPostText(event.target.value);
                  }}
                />
          </div>
          </div>
          {/* <BottomFooter/> */}
    </div>
  );
};

export default Createblog;
