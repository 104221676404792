import React from 'react'
import './M_header.css'

const M_header = () => {

  return (
    <div className="Mags_header" >
        <h2>Magazines</h2>
        <h4>10 minutes with the star </h4>
    </div>
  )
}

export default M_header