import React, { useEffect, useState } from 'react';
import { doc, getDoc, collection, addDoc, getDocs } from 'firebase/firestore';
import { db } from '../../../../../firebase-config';
import { useParams } from 'react-router-dom';

import './CommentsSec.css'
import Footer from '../../../../../../Home/Footer/Footer';

const CommentsSec = () => {

    const { postId } = useParams();
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');

    useEffect(() => {
        const fetchComments = async () => {
        try {
            const commentsCollection = collection(db, 'comments');
            const commentsSnapshot = await getDocs(commentsCollection);
            
            // Filter comments based on postId
            const postComments = commentsSnapshot.docs
            .filter(doc => doc.data().postId === postId)
            .map(doc => ({ ...doc.data(), id: doc.id }));
    
            setComments(postComments);
        } catch (error) {
            console.error('Error fetching comments:', error.message);
        }
        };
    
        fetchComments();
    }, [postId]);


const handleCommentSubmit = async () => {

    if (!newComment.trim()) {
        // If the comment is empty or contains only whitespace, do not submit
        console.warn('Comment cannot be empty');
        return;
      }

    try {
      const newCommentDoc = await addDoc(collection(db, 'comments'), {
        postId,
        text: newComment,
      });
  
      setComments([...comments, { text: newComment, id: newCommentDoc.id }]);
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error.message);
    }
  };
  



  return (
     <div className="commentsSection">
        <h2>What are your thoughts?</h2>
        <textarea
          placeholder="Leave a comment..."
          value={newComment}
          onChange={e => setNewComment(e.target.value)}
        />

        <button onClick={handleCommentSubmit}>Submit Comment</button>

        <h2 className="Pplsthoughts">Other people’s thoughts regarding this blog</h2>

        {comments.map(comment => (
          <div className="comment" key={comment.id}>
            <p>&#128100;  {comment.text}</p>
          </div>
        ))}
        
      </div>
  )
}

export default CommentsSec