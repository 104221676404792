import React from 'react'
import './Bl_header.css'

function Bl_Header() {
  return (
    <div className="Bl_Header">
         <h2>Blogs</h2>
        <h4>10 minutes with the star </h4>
    </div>
  )
}

export default Bl_Header