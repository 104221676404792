

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, storage } from '../Blogs/firebase-config';
import { collection, getDocs, deleteDoc, doc, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Navbar from '../Header/Navbar/Nav';
import './CreateAd.css';

const CreateAd = () => {
  const [ads, setAds] = useState([]);
  const [adImage, setAdImage] = useState(null);
  const [adLink, setAdLink] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAds = async () => {
      const adsCollection = collection(db, 'ads');
      const adsSnapshot = await getDocs(adsCollection);
      const adsList = adsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAds(adsList);
    };

    fetchAds();
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAdImage(file);
    }
  };

  const handleLinkChange = (event) => {
    setAdLink(event.target.value);
  };

  const addAd = async () => {
    if (adImage && adLink) {
      // Upload the image to Firebase Storage
      const storageRef = ref(storage, `ads/${adImage.name}`);
      await uploadBytes(storageRef, adImage);
      const imageUrl = await getDownloadURL(storageRef);

      // Save the ad data to Firestore
      const newAd = { image: imageUrl, link: adLink };
      const adsCollection = collection(db, 'ads');
      const docRef = await addDoc(adsCollection, newAd);
      setAds([...ads, { id: docRef.id, ...newAd }]);
      setAdImage(null);
      setAdLink('');
    }
  };

  const deleteAd = async (adId) => {
    const adDocRef = doc(db, 'ads', adId);
    await deleteDoc(adDocRef);
    const updatedAds = ads.filter(ad => ad.id !== adId);
    setAds(updatedAds);
  };

  const postAds = () => {
    // Navigate to the Blogs page with the current ads state
    navigate('/Blogs', { state: { ads } });
  };

  return (
    <div className="CreateAd-wrapper">
      <Navbar/>
      <div className="ad-creation-area">
        {ads.map((ad) => (
          <div className="AdUpload" key={ad.id}>
            <img src={ad.image} alt="Ad"/>
            <a href={ad.link} target="_blank" rel="noopener noreferrer" >{ad.link}</a>
            <button onClick={() => deleteAd(ad.id)}>
              Delete
            </button>
          </div>
        ))}

        <div className="fileUpload">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            id="upload-button"
          />
          <label htmlFor="upload-button">
            <div className="Ad-image-area">
              {adImage ? (
                <img src={URL.createObjectURL(adImage)} alt="Ad"/>
              ) : (
                <>
                  <div className="add-icon">+</div>
                  <div>Add another ad</div>
                  <p>Click or drag and drop image to here (1080 x 1920)</p>
                </>
              )}
            </div>
          </label>

          <input
            className="link-input"
            type="text"
            placeholder="Capture your link here"
            value={adLink}
            onChange={handleLinkChange}
          />
          <button onClick={addAd}> Add Ad </button>
        </div>
      </div>
    </div>
  );
}

export default CreateAd;




