// import React from 'react';
// import { Link } from 'react-router-dom';
// import './IntNavBar.css';

// function IntNavbar({ isAuth, signUserOut }) {

//   return (
    
//     <div className="IntNavbar">

//         <div className="logo">
//             <img src='Assets/10minwithstar_logo.svg' alt='10minwithstar_logo'/>
//         </div>

//         <h2>Signed in as internal user </h2>

//         {/* <Link to="/CreateMag" className="CB">Create Mag</Link> */}

//       <Link to="/Createblog" className="CB">Create Blog</Link>

//       {!isAuth ? (
//         <Link to="/Bloglogin">Blog Signin</Link>
//       ) : (
//         <button onClick={signUserOut}>Sign out</button>
//       )}
//     </div>
//   );
// }

// export default IntNavbar;


import React from 'react';
import { Link } from 'react-router-dom';
import './IntNavBar.css';

const IntNavbar = ({ isAuth, signUserOut, profilePicUrl }) => {
  return (
    <nav className="IntNavbar">
      <div className="logo">
            <img src='Assets/10minwithstar_logo.svg' alt='10minwithstar_logo'/>
        </div>
      <h2>Internal Navbar</h2>
      {isAuth && (
        <>
          <Link to="/Signedin" className="CB">CMS Dashboard</Link>
          <button onClick={signUserOut}>Sign Out</button>
          <img className="profileImage" src={profilePicUrl} alt="User Profile" />
        </>
      )}
    </nav>
  );
};

export default IntNavbar;

