import React from 'react'
import './header.css';
import Navbar from './Navbar/Nav';
import HeaderAlone from './headerAlone';


const Header = () => {
  return (
        <>
            <HeaderAlone/>
            <Navbar/>
        </>
  )
}

export default Header

