import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, deleteDoc, getDocs, doc, serverTimestamp, orderBy, query, updateDoc } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';

import { auth, db, storage } from '../../../firebase-config';

import DOMPurify from 'dompurify'; // Import DOMPurify for HTML sanitization






import { getAuth, onAuthStateChanged } from 'firebase/auth';

import DetailedPost from './DetailedPost/DetailedPost';

import './PFPost.css'
import he from 'he';

// function PostFromPost({isAuth, limit, displayName}) {
function PostFromPost({limit, displayName}) {

  const [postLists, setPostList] = useState([])
  const postCollectionRef = collection(db, "posts")


  const [loading, setLoading] = useState(true);



  const deletePost = async (id, imageUrl) => {
    try {
      // Delete post document
      const postDoc = doc(db, "posts", id);
      await deleteDoc(postDoc);
  
      // Delete image from storage if imageUrl exists
      if (imageUrl) {
        const imageRef = ref(storage, imageUrl);
        await deleteObject(imageRef);
      }
  
      // Update UI to reflect deletion by removing the post from the postLists state
      setPostList(prevPosts => prevPosts.filter(post => post.id !== id));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  }
  

  const [isAuth, setIsAuth] = useState(false); // State to track authentication status


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuth(!!user); // Update authentication state
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, []);


  useEffect(() => {

    const fetchPosts = async () => {
      try {
        const querySnapshot = await getDocs(query(postCollectionRef, orderBy('timestamp', 'desc'), limit ? limit : undefined));
        const posts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPostList(posts);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching posts:', error);
        setLoading(false); // Set loading to false to prevent indefinite loading
      }
    };

    fetchPosts();
  }, [limit]);


  useEffect(() => {

    const getPosts = async () => {
      try {
        const querySnapshot = await getDocs(query(postCollectionRef, orderBy('timestamp', 'desc')));
        const posts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const latestPosts = posts.slice(0, limit); // Get the latest 'limit' number of posts
        setPostList(latestPosts);
        // setPostList(posts);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching posts:', error);
        setLoading(false);
      }
    };
        

    getPosts();
  }, [ limit]);



  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return text.substring(0, limit) + '...';
    }
    return text;
  };

  // // Function to render post text with clickable links
  // const renderPostText = (text) => {
  //   // Sanitize HTML content using DOMPurify
  //   const sanitizedHtml = DOMPurify.sanitize(text);
  //   // Render HTML content dangerously
  //   return { __html: sanitizedHtml };
  // };

  // const transformUrlsToLinks = (text) => {
  //   return text.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');
  // };
  const transformUrlsToLinks = (text) => {
    const slicedText = text.slice(0, 200); // Slice the text to 200 characters
    return slicedText.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');
  };
  
  const renderPostText = (text) => {
    // Sanitize HTML content using DOMPurify
    const sanitizedHtml = DOMPurify.sanitize(text);
    // Render HTML content dangerously
    return { __html: sanitizedHtml };
  };
  

  return (
    <div className="PostFromPost">
      {loading ? (
        <div className="loading-spinner">

        {/* Replace this with your loading spinner HTML */}
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>

      ) : (
        postLists.map(post => {
          const postDate = post.timestamp ? new Date(post.timestamp.seconds * 1000) : null;

          return (
            <div className="post" key={post.id}>
              <div className="postheader">
                <img src={post.imageUrl} alt="Post" />
                <div className="title">
                  <h1>{post.title}</h1>
                  <div className="deletePost">
                    {/* {isAuth && post.author.id === auth.currentUser?.uid && (
                      <button onClick={() => deletePost(post.id)}>&#128465;</button>
                    )} */}

                      {isAuth && auth.currentUser?.uid === post.author.id && (
                        <button onClick={() => deletePost(post.id, post.imageUrl)}>&#128465;</button>
                      )}

                  </div>
                </div>
              </div>
              <div className="postTextContainer">
                 {/* Render post text with clickable links */}
                 
  
                 {/* <div dangerouslySetInnerHTML={renderPostText(transformUrlsToLinks(post.postText))} /> */}
                 <div dangerouslySetInnerHTML={renderPostText(transformUrlsToLinks(post.postText))} />



                {/* {truncateText(post.postText, 200)} */}

                <Link to={`/post/${post.id}`} state={{ post }}>Read more</Link>
              </div>
              {/* <h3>{`@${displayName || '10 minutes with the star'}`}</h3> */}
              <h3>{`@${displayName || post.author.name|| '10 minutes with the star'}`}</h3>
              {postDate && (
                <p className="Datetime">
                  Posted {postDate.toLocaleString('en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                </p>
              )}
            </div>
          );
        })
      )}
    </div>
  );
}
export default PostFromPost