
import React, { useEffect, useState } from 'react';
import { getDocs, collection } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { db } from '../../Blogs/firebase-config';
import './Magazine.css';

const Magazine = () => {
  const [magazines, setMagazines] = useState([]);

  useEffect(() => {
    const fetchMagazines = async () => {
      try {
        const magazineCollectionRef = collection(db, 'Magazines');
        const data = await getDocs(magazineCollectionRef);
        const magazinesList = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        
        // Sort magazines by timestamp in descending order
        const sortedMagazines = magazinesList.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);

        // Limit to 5 most recent magazines
        setMagazines(sortedMagazines.slice(0, 5));
      } catch (error) {
        console.error('Error fetching magazines:', error);
      }
    };

    fetchMagazines();
  }, []);

  // Function to format date
  const formatDate = (seconds) => {
    const date = new Date(seconds * 1000);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).replace(/ /g, ' '); // Add space after month
  };

  return (
    <div data-section className="magazine" id="magazine">
      <div className="magazine_hero">
        <h3>Magazines</h3>
      </div>

      <div className="landing_mags_wrapper">
        {magazines.map((magazine) => (
          <div 
            key={magazine.id} 
            className="magz" 
            onClick={() => window.location.href = `/Magazine-viewer/${magazine.id}`} 
            id={`mag${magazine.id}`}
          >
            <img src={magazine.pages[0]} alt={magazine.title} />
            <h4>{magazine.title}</h4>
            <p>{formatDate(magazine.timestamp.seconds)}</p>
          </div>
        ))}

        {/* // This is the static magazines to be removed on every new one added by auto mag */}

        <div className="magz" onClick={() => window.location.href = 'Turn/Magz/mag-EGG-ZENANDE/index.html'} id="mag1">
                        <img src="Assets/MagCovers/MAGCover-EGG-Zenande.jpg" alt='EGG-ALPHI SIPHO'/>
                        <h4>ZENANDE MFENYANA (June 2024)</h4>
                </div>

                <div className="magz" onClick={() => window.location.href = 'Turn/Magz/mag-EGG-ALPHI-SIPHO/index.html'} id="mag2">
                       <img src="Assets/MagCovers/MAGCover-EGG-ALPHISIPHO.jpg" alt='EGG-ALPHI SIPHO'/>
                         <h4>ALPHI SIPHO (April 2024)</h4>
                   </div>

                   <div className="magz" onClick={() => window.location.href = 'Turn/Magz/mag-EGG-GLL/index.html'} id="mag3">
                        <img src="Assets/MagCovers/MAGCover-EGG-GLL.jpg" alt='EGG-Gll'/>
                         <h4>GIVEN ZULU, LADY Z...(Mar 2024)</h4>
                    </div>

                    <div className="magz" onClick={() => window.location.href = 'Turn/Magz/mag-EGG/index.html'} id="mag5">
                       <img src="Assets/MagCovers/MAGCover-EGG.jpg" alt='MAGCover-EGG'/>
                        <h4>DONALD BU & YALLUNDER(February)</h4>
                    </div>

        
        {/* View more button */}
        <Link to="/magz">
          <div className="magz" id="View-more">
            <div className="next">
              <img src='Assets/MAGAZINES.jpg' alt='view more' />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Magazine;
