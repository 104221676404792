// import React, { useState, useEffect } from 'react'; // Import useEffect from 'react'
// import './Mags_area.css'
// import { db } from '../../Blogs/firebase-config';
// import { collection, getDocs } from 'firebase/firestore';
// import { useNavigate } from 'react-router-dom';


// function AccordionItem({ title, content, isOpen, toggleAccordion, imageUrl ,magazineLink}) {
//   // function AccordionItem({ title, content, isOpen, toggleAccordion, imageUrl}) {
    

// return (
//   <div className={`accordion-item ${isOpen ? 'open' : ''}`} onClick={toggleAccordion}>
//     <div className="accordion-header">
//       <h3>{title}</h3>
//       <span>{isOpen ? '' : ''}</span>
//     </div>
//     {isOpen && (
//       <div className="accordion-content">
//         {content}
//         {/* <img src={imageUrl} alt={title} /> */}
//       </div>
//     )}
//   </div>
// );

// }

// function Accordion({ magazines }) { //new
//   // function Accordion() {
//   const [items, setItems] = useState([
//     // this one Well be used in 2024 mags
//     { title: '2024', 
//         content: <div className="Year_2024 year">
//           <div className="row_one mag_row">

//           {/* <div className="autoMag"> */}
//           {/* <h2>Magazines</h2> */}
//           {/* <div className="magazineTiles "> */}
//             {magazines && magazines.map((magazine) => (
//               <div className="magazineTile mag_one mag" key={magazine.id} onClick={() => handleMagazineClick(magazine)}>
//                   <h3>{magazine.title}</h3>
//                   <img src={magazine.pages && magazine.pages.length > 0 ? magazine.pages[0] : ''} alt={magazine.title} />
//                 </div>
//               ))}
//             {/* </div> */}
//           {/* </div> */}

//           <div className="mag_one mag" onClick={() => window.location.href = 'Turn/Magz/mag-EGG-ZENANDE/index.html'}>
//               <img src="Assets/MagCovers/MAGCover-EGG-Zenande.jpg" alt='EGG-Gll'/>
//               <h4>ZENANDE MFENYANA PAPAGHOST</h4>
//               <h5>June</h5>
//             </div>


//           <div className="mag_one mag" onClick={() => window.location.href = 'Turn/Magz/mag-EGG-ALPHI-SIPHO/index.html'}>
//               <img src="Assets/MagCovers/MAGCover-EGG-ALPHISIPHO.jpg" alt='EGG-Gll'/>
//               <h4>ALPHI SIPHO</h4>
//               <h5>APRIL</h5>
//             </div>


//           <div className="mag_one mag" onClick={() => window.location.href = 'Turn/Magz/mag-EGG-GLL/index.html'}>
//               <img src="Assets/MagCovers/MAGCover-EGG-GLL.jpg" alt='EGG-Gll'/>
//               <h4>GIVEN ZULU LADY ZAMA LERATO MOKOKA</h4>
//               <h5>March</h5>
//             </div>

//           <div className="mag_one mag" onClick={() => window.location.href = 'Turn/Magz/mag-EGG/index.html'}>
//               <img src="Assets/MagCovers/MAGCover-EGG.jpg" alt='MAGCover-EGG'/>
//               <h4>DONALD BU & YALLUNDER</h4>
//               <h5>February</h5>
//             </div>

//             <div className="mag_one mag" onClick={() => window.location.href = 'Turn/Magz/mag-DEC&JAN/index.html'}>
//               <img src="Assets/MagCovers/MAGCover-DEC&JAN.jpg" alt='MAGCover-DEC&JAN'/>
//               <h4>DEC & JAN</h4>
//               <h5>January</h5>
//             </div>

//           </div>
//         </div>,

//         isOpen: true 
//     },

//     { title: '2023', 
//         content: <div className="Year_2023 year">
//                     <div className="row_one mag_row">

//                         <div className="mag_one mag" onClick={() => window.location.href = 'Turn/Magz/mag-IGAGU/index.html'}>
//                             <img src="Assets/MagCovers/MAGCover-IGAGU.jpg" alt='MAGCover-IGAGU'/>
//                             <h4>IGAGU</h4>
//                             <h5>September</h5>
//                         </div>

//                         <div className="mag_two mag" onClick={() => window.location.href = 'Turn/Magz/mag-WOMEN/index.html'}>
//                         <img src="Assets/MagCovers/MAGCover-Women.jpg" alt='Empowering women'/>
//                             <h4>Women</h4>
//                             <h5>August</h5>
//                         </div>

//                         <div className="mag_three mag" onClick={() => window.location.href = 'Turn/Magz/mag-KLY/index.html'}>
//                         <img src="Assets/MagCovers/MAGCover-KLY.jpg" alt='The return of KLY'/>
//                             <h4>KLY</h4>
//                             <h5>July</h5>
//                         </div>

//                         <div className="mag_four mag" onClick={() => window.location.href = 'Turn/Magz/mag-TBO-Touch/index.html'}>
//                         <img src="Assets/MagCovers/MAGCover-TBO-Touch.jpg" alt='TBO Touch'/>
//                             <h4>TBO Touch</h4>
//                             <h5>June</h5>
//                         </div>

//                     </div>
//                 </div>, 
//         isOpen: false 
//     },

//     { title: '2022', 
//     content: <div className="Year_2022 year">
//              <div className="row_one mag_row">

//                 <div className="mag_one mag" onClick={() => window.location.href = 'Turn/Magz/mag-Murmba-Pitch/index.html'}>
//                 <img src="Assets/MagCovers/MAGCover-MPITCH.jpg" alt='Murumba Pitch'/>
//                     <h4>Murumba Pitch</h4>
//                     <h5>November</h5>
//                 </div>

//                 <div className="mag_two mag" onClick={() => window.location.href = 'Turn/Magz/mag-FLVME/index.html'}>
//                 <img src="Assets/MagCovers/MAGCover-FLVME.jpg" alt='FLVME'/>
//                     <h4>FLVME</h4>
//                     <h5>October</h5>
//                 </div>

//                 <div className="mag_three mag" onClick={() => window.location.href = 'Turn/Magz/mag-Zamani-Ndimande/index.html'}>
//                 <img src="Assets/MagCovers/MAGCover1.png" alt='Z Ndimande'/>
//                     <h4>Z Ndimande</h4>
//                     <h5>September</h5>
//                 </div>

//                 <div className="mag_four mag" onClick={() => window.location.href = 'Turn/Magz/mag-Thato-MBHA/index.html'}>
//                 <img src="Assets/MagCovers/MAGCover-TTMBHA.jpg" alt='Thato MBHA Pitch'/>
//                     <h4>TTMBHA</h4>
//                     <h5>August</h5>
//                 </div>

//                 </div>
//             </div>, 

//     isOpen: false },

//   ]);

  
//   // New
//   const navigate = useNavigate();

//     useEffect(() => {
//     const fetchMagazines = async () => {
//       try {
//         const magazineCollectionRef = collection(db, 'Magazines' ,items);
//         const snapshot = await getDocs(magazineCollectionRef);
//         const magazineList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//         console.log('Magazines fetched:', magazineList);
//         setItems(magazineList.map(magazine => ({
//           // title: magazine.title,
//           content: magazine.content,
//           isOpen: false,
//           imageUrl: magazine.pages && magazine.pages.length > 0 ? magazine.pages[0] : '',
//         })));
//       } catch (error) {
//         console.error('Error fetching magazines:', error);
//       }
//     };

//     fetchMagazines();
//   }, []);


//   // -------old

//   const toggleAccordion = (index) => {
//     const updatedItems = items.map((item, i) => ({
//       ...item,
//       isOpen: i === index ? !item.isOpen : false,
//     }));
//     setItems(updatedItems);
//   };


//   // New

//   const handleMagazineClick = (magazine) => {
//         if (magazine && magazine.id) {
//           navigate(`/magazine-viewer/${magazine.id}`);
//         } else {
//           console.error('Invalid magazine data:', magazine);
//           alert('Invalid magazine data. Unable to view magazine.');
//         }
//       };

//   return (
//         // <div className="accordion">
//         //   {items.map((item, index) => (
//         //     <AccordionItem
//         //       key={index}
//         //       title={item.title}
//         //       content={item.content}
//         //       isOpen={item.isOpen}
//         //       toggleAccordion={() => toggleAccordion(index)}
//         //       imageUrl={item.imageUrl}
//         //     />
//         //   ))}
//         // </div>



//         <div className="accordion">
//           {items.map((item, index  ) => (
//             <AccordionItem
//               key={index}
//               title={item.title}
//               content={item.content}
//               isOpen={item.isOpen}
//               toggleAccordion={() => toggleAccordion(index)}
//               imageUrl={item.imageUrl}

//             />
//           ))}
          
//       </div>
            
//       );
// }

// export default Accordion;



// ----------------------------------------------Orginal code above "Do not delete-----------------------------------------------------
// ---------------------------------------------------------------------------------------------------




import React, { useState, useEffect } from 'react';
import './Mags_area.css';
import { db } from '../../Blogs/firebase-config';
import { collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Automatic from './Automatic';

function AccordionItem({ title, content, isOpen, toggleAccordion, imageUrl, magazines }) {
 
  return (
    <div className={`accordion-item ${isOpen ? 'open' : ''}`} onClick={toggleAccordion}>
      <div className="accordion-header">
        <h3>{title}</h3>
        <span>{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && (
        <div className="accordion-content">
          {content}
          <img src={imageUrl} alt={title} />
        </div>
      )}
    </div>
  ); 
}





function Accordion({ magazines}) {
  // const [items, setItems] = useState([]);
  // const navigate = useNavigate();

  const navigate = useNavigate();
  const handleMagazineClick = (magazine) => {

    if (magazine && magazine.id) {
      navigate(`/magazine-viewer/${magazine.id}`);
    } else {
      console.error('Invalid magazine data:', magazine);
      alert('Invalid magazine data. Unable to view magazine.');
    }
  };


  const [items, setItems ] = useState([
        // this one Well be used in 2024 mags

       
        { title: '2024', 
          content: <div className="Year_2024 year">
            <div className="row_one mag_row">

            </div>
          </div>,
  
          isOpen: true , 
         
      },      
    
        { title: '2023', 
            content: <div className="Year_2023 year">
                        <div className="row_one mag_row">
    
                           
                        </div>
                    </div>, 
            isOpen: false 
        },
    
        { title: '2022', 
        content: <div className="Year_2022 year">
                 <div className="row_one mag_row">
    
    
                    </div>
                </div>, 
    
        isOpen: false },
    
      ]);
      

    // Use effect to generate preview images
  useEffect((content) => {
    const fetchMagazines = async () => {

      const magazineCollectionRef = collection(db, 'Magazines');

      const newItems = magazines.map((magazine) => (
        
        {
        title: magazine.title,
        content: (

          
          <>
          
       
          </>

 
        ),

        isOpen: true,
        // imageUrl: magazine.pages && magazine.pages.length > 0 ? magazine.pages[0] : '',
      }
    
    ));

      setItems(newItems);
    };

    fetchMagazines();
  }, [magazines]);


  const toggleAccordion = (index) => {
    const updatedItems = items.map((item, i) => ({
      ...item,
      isOpen: i === index ? !item.isOpen : false,
    }));
    setItems(updatedItems);
  };

  return (
          <>
            <div className="block">

                  <div className="Year_2024 year">

            <div className="row_one mag_row" id="row-one">

                <Automatic className="auto" magazines={magazines} handleMagazineClick={handleMagazineClick}/>
              
            </div>

            </div>

          </div>
      </>

  );
}

export default Accordion;



