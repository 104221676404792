// import './App.css';
// import { useState } from 'react';
// import About_us from './Components/About/About_us/About';
// import Contact from './Components/About/Contact_us/Contact';
// import Find from './Components/About/Find_us/Find';
// import Services from './Components/About/Services/Services';
// import Header from './Components/Header/header';
// import Content from './Components/Home/Content/Content';
// import Footer from './Components/Home/Footer/Footer';
// import Landing from './Components/Home/Landing/Landing';
// import Magazine from './Components/Home/Magazine/Magazine';
// import Magz from './Components/Mags/Magz_landing/Magz';
// import './Components/Header/Navbar/Nav.js';


// // import React, { useState, useEffect, useRef } from "react";
// import {BrowserRouter as Router, Route, Routes, Link ,useParams } from 'react-router-dom';
// // import {HashRouter as Router, Route, Routes, Link } from 'react-router-dom';
// import Navbar from './Components/Header/Navbar/Nav.js';
// import Blogs from './Components/Blogs/BlogsLanding/Blogs';
// import Bloglogin from './Components/Blogs/BlogsLogin/Bloglogin';
// import Createblog from './Components/Blogs/BlogsCreation/Createblog';
// import { signOut } from 'firebase/auth';
// import { auth } from './Components/Blogs/firebase-config';
// import DetailedPost from './Components/Blogs/BlogsLanding/Accordion/BlogPosts/DetailedPost/DetailedPost.js';
// import BRLanding from './Components/Home/BlogsRv/BRLanding.js';
// import IntNavbar from './Components/Header/IntNavbar/IntNavbar.js';
// import CreateMagazine from './Components/Mags/Mags_area/CreateMag.js';
// import MagazineViewer from './Components/Mags/Mags_area/MagazineViewer.js';
// import Signedin from './Components/Signedin/Signedin.js';






// function App() {

// // this state is used primarly to check blog login status
//   const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"))

//   // this is the sign out functionality
//   const signUserOut = () =>{
//     signOut(auth).then(() => {
//       localStorage.clear()
//       setIsAuth(false)
//       window.location.pathname = "/Bloglogin"
//     })
//   }
  
//   return (

//     <>
//     <style>
//     {`
//        @import url('https://fonts.googleapis.com/css2?family=Hurricane&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
//       @import url('https://fonts.googleapis.com/css2?family=Hurricane&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
//       /* Your additional CSS styles can go here */
//     `}
//   </style>

//     <Router>
//       <div className="App">
//           <Routes>
//             <Route exact path="/" element={ 
//               <>
//               {isAuth && <IntNavbar isAuth={isAuth} signUserOut={signUserOut} />}
//                 <Header/>
//                 <Landing/>
//                 <Magazine/>
//                 <BRLanding/>
//                 <Content/>
//                 <Footer/>
//               </>
//             }/>
          
//           <Route path="/About" element={
//               <>
//               {isAuth && <IntNavbar isAuth={isAuth} signUserOut={signUserOut} />}
//               <Header/>
//               <About_us/>
//               <Services/>
//               <Contact/>
//               {/* <Find/> */}
//               {/* <Footer/> */}
//               </>
//           }/>

//           <Route path="/Magz" element={
//               <>
//               {isAuth && <IntNavbar isAuth={isAuth} signUserOut={signUserOut} />}
//               <Header/>
//               {/* <Navbar/> */}
//               <Magz/>
//               </>
//           }/>

//           <Route path="/Blogs" element={
//               <>
//               {isAuth && <IntNavbar isAuth={isAuth} signUserOut={signUserOut} />}
//               {/* <Link to="/Bloglogin">Blog Signin</Link> */}
//               {/* <Navbar/> */}
//               <Blogs isAuth ={isAuth}/>
//               </>
//           }/>

//           <Route path="/Createblog" element={
//               <>
//               {/* {isAuth && <IntNavbar isAuth={isAuth} signUserOut={signUserOut} />} */}
//               {/* <Navbar/> */}
//               <Createblog isAuth={isAuth}/>

//               </>
//           }/>

//           <Route path="/Bloglogin" element={
//               <>
//             {/* { !isAuth && <Link to="/Createblog">Create Blog</Link>} */}
//               {/* <Navbar/> */}
//               <Bloglogin setIsAuth={setIsAuth}/>
//               </>
//           }/>

//           <Route path="/CreateMag" element={
//               <>
//               {/* {isAuth && <IntNavbar isAuth={isAuth} signUserOut={signUserOut} />} */}
//               {/* <Navbar/> */}
//               <CreateMagazine/>

//               </>
//           }/>

//           <Route path="/Signedin" element={
//               <>
//               {/* {isAuth && <IntNavbar isAuth={isAuth} signUserOut={signUserOut} />} */}
//               {/* <Navbar/> */}
//               <Signedin isAuth={isAuth}/>
//               </>
//           }/>
          
//           // Assuming you have a route set up for MagazineViewer
//           <Route path="/magazine-viewer/:magazineUrl" element={<MagazineViewer />} />

//           <Route path="/post/:postId" element={<DetailedPost />} />
//         </Routes>

//       </div>
//     </Router>
//     </>
//   );
// }

// export default App;


import './App.css';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About_us from './Components/About/About_us/About';
import Contact from './Components/About/Contact_us/Contact';
import Services from './Components/About/Services/Services';
import Header from './Components/Header/header';
import Content from './Components/Home/Content/Content';
import Footer from './Components/Home/Footer/Footer';
import Landing from './Components/Home/Landing/Landing';
import Magazine from './Components/Home/Magazine/Magazine';
import Magz from './Components/Mags/Magz_landing/Magz';
import Blogs from './Components/Blogs/BlogsLanding/Blogs';
import Bloglogin from './Components/Blogs/BlogsLogin/Bloglogin';
import Createblog from './Components/Blogs/BlogsCreation/Createblog';
import DetailedPost from './Components/Blogs/BlogsLanding/Accordion/BlogPosts/DetailedPost/DetailedPost';
import BRLanding from './Components/Home/BlogsRv/BRLanding';
import IntNavbar from './Components/Header/IntNavbar/IntNavbar';
import CreateMagazine from './Components/Mags/Mags_area/CreateMag';
import MagazineViewer from './Components/Mags/Mags_area/MagazineViewer';
import Signedin from './Components/Signedin/Signedin';
import { signOut } from 'firebase/auth';
import { auth } from './Components/Blogs/firebase-config';
import { AuthProvider } from './Components/Signedin/AuthContext';
import CreateAd from './Components/Ads/CreateAd';

function App() {
  const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"));
  const [profilePicUrl, setProfilePicUrl] = useState(localStorage.getItem("profilePicUrl"));

  const signUserOut = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      setIsAuth(false);
      setProfilePicUrl(null);
      window.location.pathname = "/Bloglogin";
    });
  };

  useEffect(() => {
    if (isAuth) {
      const storedProfilePicUrl = localStorage.getItem("profilePicUrl");
      setProfilePicUrl(storedProfilePicUrl);
    }
  }, [isAuth]);

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          {isAuth && <IntNavbar isAuth={isAuth} signUserOut={signUserOut} profilePicUrl={profilePicUrl} />}
          <Routes>
            <Route exact path="/" element={
              <>
                <Header />
                <Landing />
                <Magazine />
                <BRLanding />
                <Content />
                <Footer />
              </>
            } />
            <Route path="/About" element={
              <>
                <Header />
                <About_us />
                <Services />
                <Contact />
              </>
            } />
            <Route path="/Magz" element={
              <>
                <Header />
                <Magz />
              </>
            } />
            <Route path="/Blogs" element={
              <>
                <Blogs isAuth={isAuth} />
              </>
            } />
            <Route path="/Createblog" element={<Createblog isAuth={isAuth} />} />
            <Route path="/Bloglogin" element={<Bloglogin setIsAuth={setIsAuth} setUserProfile={setProfilePicUrl} />} />
            <Route path="/CreateMag" element={<CreateMagazine isAuth={isAuth}/>} />
            <Route path="/Signedin" element={<Signedin isAuth={isAuth} profilePicUrl={profilePicUrl} />} />
            <Route path="/create-ad" element={<CreateAd isAuth={isAuth}/>} /> {/* Add the route for the CreateAd component */}
            {/* <Route path="/magazine-viewer/:magazineUrl" element={<MagazineViewer />} /> */}
            <Route path="/Magazine-Viewer/:magazineId" element={<MagazineViewer />} />
            <Route path="/post/:postId" element={<DetailedPost />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;


