import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyAdj-Ya8HkXA2jk9ikl1QTZHBTXPuXstLw",
  authDomain: "minblogs-bc3fa.firebaseapp.com",
  projectId: "minblogs-bc3fa",
  storageBucket: "minblogs-bc3fa.appspot.com",
  messagingSenderId: "37446819082",
  appId: "1:37446819082:web:72829cb36a93163b4aeef3",
  measurementId: "G-YE0PCXMCHB"
};


const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const storage = getStorage(app);



// Environment testing
// -------------------------------------------------------------------------------


// import { initializeApp } from 'firebase/app';
// import { getFirestore } from 'firebase/firestore';
// import { getAuth, GoogleAuthProvider } from 'firebase/auth';
// import { getStorage } from 'firebase/storage';
// import { ref, getDownloadURL,deleteObject} from 'firebase/storage';




// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyD12XY4YuuPoClmOFNOfux5ZbGEbMRPR04",
//   authDomain: "environmenttesting-be55d.firebaseapp.com",
//   projectId: "environmenttesting-be55d",
//   storageBucket: "environmenttesting-be55d.appspot.com",
//   messagingSenderId: "868363871720",
//   appId: "1:868363871720:web:71c59ff12f4e59ee48b05d"
// };


// const app = initializeApp(firebaseConfig);

// export const db = getFirestore(app);
// export const auth = getAuth(app);
// export const provider = new GoogleAuthProvider();
// export const storage = getStorage(app);