import React, { useState, useEffect ,useRef } from 'react'
import './Footer.css'
import { Link } from 'react-router-dom';

function BottomFooter() {

    // -----------------Show and Hide------------

  const [isDivShown, setIsDivShown] = useState(false);

    const showDiv = () => {
        setIsDivShown(true);
    }

    const hideDiv = () => {
        setIsDivShown(false);
    }

  return (
    <div>
           <div className="footer">

                <div className="bottom-nav">

                    <nav>
                        {/* Navbar content here */}

                        <div className="nav__slider"></div>

                        <Link to="/" className="b-nav__item" onClick={hideDiv}>Home</Link>

                        {!isDivShown &&
                        <a href='#magazine' className="b-nav__item"  >Magazines</a>
                        }

                        {!isDivShown &&
                        <a href='#articles' className="b-nav__item" >Articles</a>

                        }

                        {!isDivShown &&
                        <a href='#newsletter'  className="b-nav__item" >Newsletter </a> 
                        }
                        
                        <div className="nav__item"> | </div>

                            <Link to="/about"  className="b-nav__item" onClick={showDiv}>About us</Link>

                            {isDivShown &&
                            <a href='#services'  className="b-nav__item" >Services</a>
                            }

                            {isDivShown && 
                            <a href='#contact_us'  className="b-nav__item" >Contact us</a>
                            }
                    </nav>
                </div>

                <hr />

                <div className="bottom-socials-wrapper">

                    <div className="socials">
                                
                    <a href="https://www.youtube.com/channel/UCNEwm_YhSyt9fQ5KaMr6J0Q" id="social-one" className="social" >
                            <img src="Assets/Socials/Youtube_BW.png" alt=""/>
                        </a>

                        <a href="https://instagram.com/10minutes_withthestar?igshid=YmMyMTA2M2Y=" id="social-two" className="social" >
                        <img src="Assets/Socials/Instagram_BW.png" alt=""/>
                        </a>

                        <a href="https://www.tiktok.com/@10minutes_withthestar" id="social-three" className="social" >
                        <img src="Assets/Socials/TikTock_BW.png" alt=""/>
                        </a>

                        <a href="https://www.facebook.com/10MWTS?mibextid=LQQJ4d" id="social-four" className="social" >
                        <img src="Assets/Socials/Facebook_BW.png" alt=""/>
                        </a>
                    </div>
                </div>

                <div className="copyright-wrapper">
                    <p id="copyright" className="copyright-style"> Copyright &copy;10minutes with the star 2024</p>
                    <p id="sponsor" className="copyright-style"> Designed and developed by Perspective Point of View @<a href='https://perspectivepov.co.za'>perspectivepov.co.za</a></p>
                </div>
                </div>

    </div>
  )
}

export default BottomFooter