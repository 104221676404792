import React, { useState, useEffect ,useRef } from 'react'
import './header.css';
import { Link } from 'react-router-dom';

const HeaderAlone = () => {

     // this state is used primarly to check blog login status
  const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"))


  return (
  
        <div className="header-wrapper">

             <div className="soicals-wrapper">

                <div className="socials">
                
                    <a href="https://www.youtube.com/channel/UCNEwm_YhSyt9fQ5KaMr6J0Q" id="social-one" className="social" >
                        <img src="/Assets/Socials/Youtube_BW.png" alt=""/>
                    </a>

                    <a href="https://instagram.com/10minutes_withthestar?igshid=YmMyMTA2M2Y=" id="social-two" className="social" >
                    <img src="/Assets/Socials/Instagram_BW.png" alt=""/>
                    </a>

                    <a href="https://www.tiktok.com/@10minutes_withthestar" id="social-three" className="social" >
                    <img src="/Assets/Socials/TikTock_BW.png" alt=""/>
                    </a>

                    <a href="https://www.facebook.com/10MWTS?mibextid=LQQJ4d" id="social-four" className="social" >
                    <img src="/Assets/Socials/Facebook_BW.png" alt=""/>
                    </a>
             
                    {/* { !isAuth && <Link to="/bloglogin" id="internal-link" className="social">

                        <img src="/Assets/Socials/Internal.png" alt='internal use'/>

                    </Link>} */}

                </div>

            </div>
            <div className="logo-banner">
                <img className='logo' src='Assets/10minwithstar_logo.svg' alt='10minutes with the start'/>
            </div>
        </div>
  )
}

export default HeaderAlone