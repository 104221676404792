import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { db, auth } from '../../../../firebase-config'; // Ensure auth is imported
import { doc, getDoc, collection, getDocs, updateDoc, query, orderBy } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth'; // Import onAuthStateChanged from firebase/auth
import sanitizeHtml from 'sanitize-html';

import './DetaildPost.css';
import Navbar from '../../../../../Header/Navbar/Nav';
import CommentsSec from './Comments/CommentsSec';
import Footer from '../../../../../Home/Footer/Footer';

const DetailedPost = () => {
  const { pathname } = useLocation();
  const postId = pathname.split('/').pop(); // Extract the postId from the URL
  const navigate = useNavigate();

  const { state } = useLocation();
  const initialPost = state && state.post;
  const [post, setPost] = useState(initialPost);

  const [readTime, setReadTime] = useState('');
  const [views, setViews] = useState(0);
  const [ads, setAds] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [previousPost, setPreviousPost] = useState(null);
  const [nextPost, setNextPost] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchPost = async () => {
      const postRef = doc(db, 'posts', postId);
      const postSnapshot = await getDoc(postRef);
      if (postSnapshot.exists()) {
        const postData = postSnapshot.data();
        setPost(postData);
        await fetchAdjacentPosts(postData);
        setViews(postData.views || 0);
      } else {
        console.log('Post not found');
      }
    };

    const fetchAds = async () => {
      const adsCollectionRef = collection(db, 'ads');
      const adsSnapshot = await getDocs(adsCollectionRef);
      const adsList = adsSnapshot.docs.map(doc => doc.data());
      setAds(adsList);
    };

    if (!initialPost) {
      fetchPost();
    } else {
      fetchAdjacentPosts(initialPost);
    }
    fetchAds();
  }, [postId, initialPost]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  }, []);

  const fetchAdjacentPosts = async (currentPost) => {
    const postsCollectionRef = collection(db, 'posts');
    const postsQuery = query(postsCollectionRef, orderBy('timestamp'));
    const postsSnapshot = await getDocs(postsQuery);

    const posts = postsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    const currentIndex = posts.findIndex(post => post.id === currentPost.id);

    if (currentIndex > 0) {
      setPreviousPost(posts[currentIndex - 1]);
    } else {
      setPreviousPost(null);
    }

    if (currentIndex < posts.length - 1) {
      setNextPost(posts[currentIndex + 1]);
    } else {
      setNextPost(null);
    }
  };

  useEffect(() => {
    const updatePostViews = async () => {
      if (post) {
        const postRef = doc(db, 'posts', postId);
        const postDoc = await getDoc(postRef);

        if (postDoc.exists()) {
          const currentViews = postDoc.data().views || 0;
          setViews(currentViews);

          // Update views count in the database
          await updateDoc(postRef, {
            views: currentViews + 1,
          });
        }
      }
    };

    const calculateReadTime = () => {
      if (post) {
        // Assuming an average reading speed of 200 words per minute
        const wordCount = post.postText.split(/\s+/).length;
        const minutes = Math.ceil(wordCount / 200);
        setReadTime(`${minutes} min read`);
      }
    };

    updatePostViews();
    calculateReadTime();
  }, [post]);

  // Scroll to the top when postId changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [postId]);

  const handleShare = () => {
    const articleLink = `${window.location.origin}/post/${post.id}`;

    if (navigator.share) {
      navigator
        .share({
          title: post.title,
          text: post.title,
          url: articleLink,
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      // Fallback for browsers that don't support Web Share API
      navigator.clipboard
        .writeText(articleLink)
        .then(() => {
          alert(`Article link copied to clipboard: ${articleLink}`);
        })
        .catch((error) => {
          console.error('Error copying to clipboard:', error);
        });
    }
  };

  if (!post) {
    return (
      <div className="postloading">
        <img src="/Assets/10Minlogo-black.png" alt="Loading..." loading="lazy" />
        <p>Loading... <div className="loader"></div></p>
      </div>
    );
  }

  // Sanitize post text to allow only certain HTML tags
  const sanitizedPostText = sanitizeHtml(post.postText, {
    allowedTags: ['a', 'p'],
    allowedAttributes: {
      a: ['href', 'target'],
    },
  });

  // Transform URLs to clickable links
  const transformUrlsToLinks = (text) => {
    return text.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');
  };

  // Split post text into paragraphs
  const paragraphs = post.postText.split('\n').map((paragraph, index) => (
    <p key={index} dangerouslySetInnerHTML={{ __html: transformUrlsToLinks(paragraph) }} />
  ));

  const handleNavigate = (postId, post) => {
    navigate(`/post/${postId}`, { state: { post } });
    setPost(post); // Update the post state
  };

  const BlogPreview = ({ post, direction }) => {
    if (!post) return null;

    const directionClass = direction === 'previous' ? 'previous' : 'next';

    return (
      <div className={`blog-preview ${directionClass}`}>
        <img src={post.imageUrl} alt="Post" />
        <div>
          <h2>{post.title}</h2>
          <a
            href={`/post/${post.id}`}
            onClick={(e) => {
              e.preventDefault();
              handleNavigate(post.id, post);
            }}
          >
            Read now
          </a>
        </div>
      </div>
    );
  };

  return (
    <div className="postFullview">
      <Navbar />

      <div className="postAd">
        {!isMobile && (
          <div className="ads-wrapper">
            <div className="ads">
              {ads.map((ad, index) => (
                <a href={ad.link} target="_blank" rel="noopener noreferrer" key={index} className="ad">
                  <img src={ad.image} alt="Ad" />
                </a>
              ))}
            </div>
          </div>
        )}

        <div className="PostImageBG">
          <div className="gradient-overlay"></div>
          <img src={post.imageUrl} alt="Post" />
        </div>

        <div className="ThePost">
          <div className="titlearea">
            <h1>{post.title}</h1>
            <h5>
              {/* @{post.author.displayName || '10 minutes with the star'} | {readTime} | {!!isLoggedIn && `${views} views |`} Posted{' '} */}
              @{post.author.displayName || '10 minutes with the star'} | {readTime} | {views} views | Posted{' '}
              {post.timestamp &&
                new Date(post.timestamp.seconds * 1000).toLocaleString('en-GB', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })}
            </h5>
          </div>
          <div className="imagearea">
            <img src={post.imageUrl} alt="Post" />
          </div>
          {paragraphs}
          <button onClick={handleShare} className="share-button">Share</button>
        </div>

        <div className="comments-area">
          <CommentsSec />
        </div>

        <div className="blogs-next-preview">
          <div className="Bpreview">
            <h5 id="previous">PREVIOUS</h5>
            <BlogPreview post={previousPost} direction="previous" />
          </div>
          <div className="Bpreview">
            <h5 id="next">NEXT</h5>
            <BlogPreview post={nextPost} direction="next" />
          </div>
        </div>

        {isMobile && (
          <div className="mobile-ads">
            <div className="ads">
              {ads.map((ad, index) => (
                <a href={ad.link} target="_blank" rel="noopener noreferrer" key={index} className="ad">
                  <img src={ad.image} alt="Ad" />
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default DetailedPost;
