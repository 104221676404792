import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css'
// import './Contact-form-handler.php'

function Contact() {

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_vuhozkn', 
      'template_7ovhehs', 
      form.current, 
      'ki5gApKRopF4srglQ')

        .then((result) => {
            console.log(result.text);
            // console.log( );
            e.target.reset();
            
            console.log('Form submitted successfully');
          // Add any necessary success logic here
            alert("Your message was successfully sent")

        }, (error) => {
            console.log(error.text);
            console.error('Form submission failed');
             alert("We unfortunately couldn't send your message please try again later else you can email us directly at info@10minuteswiththestar.co.za")
        });
    };

  return (
    <div data-section className="contact_us" id="contact_us">

        <div className="contacts-wrapper">
            <div className="contact-details">
                <a href="https://wa.link/yqhd4k" className="detail" id="whatsapp">
                    <div className="contact-icon">
                        <img src="Assets/Socials/Whatsapp.png" alt="Whatsapp" />
                    </div>
                    <h4>Whatsapp/Phone</h4>
                    <p>0682156339 / 0682373873</p>
                </a>
                <a href="mailto:enquiry@10minuteswiththestar.co.za" className="detail" id="email">
                    <div className="contact-icon">
                        <img src="Assets/Socials/Mail.png" alt="email" />
                    </div>
                    <h4>Email</h4>
                    <p>info@10minuteswiththestar.co.za</p>
                </a>
                <a href="https://goo.gl/maps/pQuL8SqAL6FBtDWc7" className="detail" id="location">
                    <div className="contact-icon">
                        <img src="Assets/Socials/Location.png" alt="Location" />
                    </div>
                    <h4>Location</h4>
                    <p>42 earls avenue Randburg Cresta</p>
                </a>
            </div>

            <div className="get-in-touch">
                <div className="GIT-content">
                    <h3>Get in touch</h3>
                    <p>
                    For any enquires, or just want to say hello, don’t shy away to get in 
                    touch with us and we’ll get back to you as soon as possible  :)
                    </p>
                </div>
                {/* <form method="post" action="Contact-form-handler.php"> */}
                <form ref={form} onSubmit={sendEmail}>

                    <input className="inpute-box" type="name" placeholder="Your name…" id="name" name="user_name" required=""/>
                    <input className="inpute-box" type="email" placeholder="Your email address…" id="email" name="user_email" required=""/>
                    <textarea className="inpute-box" type="text" placeholder="Your message…" id="message" name="message" required=""></textarea>
                    <button type="submit" value="Send" >Send message</button>

                </form>
            </div>
        </div>
    </div>
  )
}

export default Contact