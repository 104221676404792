// import React, { useEffect } from 'react'
// import './Mags.css'
// import M_header from '../Mags_header/M_header'
// import Mags_area from '../Mags_area/Mags_area'
// import Accordion from '../Mags_area/Mags_area'
// import Footer from '../../Home/Footer/Footer'


// const Magz= () => {

//   useEffect(() => {
//     window.onscroll = () => scrollFunction();

//     return () => {
//       window.onscroll = null; // Remove the event listener when the component unmounts
//     };
//   }, []);

//   function scrollFunction() {
//     if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
//       document.getElementById("header").style.fontSize = "30px";
//     } else {
//       document.getElementById("header").style.fontSize = "90px";
//     }
//   }

//   return (

   
//     <div data-section className="Mags_page" id="magz">
//        <M_header id="header"/>
//        <Mags_area/>
//        <Accordion/>
//        {/* <Accordion magazines={magazinesData} /> */}

//        <Footer/>
//     </div>
    
//   )
// }
// export default Magz



// import React, { useEffect, useState } from 'react';
// import { collection, getDocs } from 'firebase/firestore';
// import Mags_area from '../Mags_area/Mags_area'

// import './Mags.css';
// import M_header from '../Mags_header/M_header';
// import Accordion from '../Mags_area/Mags_area';
// import Footer from '../../Home/Footer/Footer';
// import { db } from '../../Blogs/firebase-config';

// const Magz = () => {
//   const [magazines, setMagazines] = useState([]);

//   useEffect(() => {
//     const fetchMagazines = async () => {
//       try {
//         const magazineCollectionRef = collection(db, 'Magazines');
//         const snapshot = await getDocs(magazineCollectionRef);
//         const magazineList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//         console.log('Magazines fetched:', magazineList);
//         setMagazines(magazineList);
//       } catch (error) {
//         console.error('Error fetching magazines:', error);
//         // Handle error here
//       }
//     };

//     fetchMagazines();
//   }, []);

//   return (
//     <div data-section className="Mags_page" id="magz">
//       <M_header id="header" />
//       <Mags_area/>
//       {/* <Accordion/> */}
//       {/* <Accordion magazines={magazines} /> */}
//       <Footer />
//     </div>
//   );
// };

// export default Magz;







import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';

import './Mags.css';
import M_header from '../Mags_header/M_header';
import Accordion from '../Mags_area/Mags_area';
import Footer from '../../Home/Footer/Footer';
import { db } from '../../Blogs/firebase-config';

const Magz = () => {
  const [magazines, setMagazines] = useState([]);

  useEffect(() => {
    const fetchMagazines = async () => {
      try {
        const magazineCollectionRef = collection(db, 'Magazines');
        const snapshot = await getDocs(magazineCollectionRef);
        const magazineList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log('Magazines fetched:', magazineList); // Log magazines data here
        setMagazines(magazineList);
      } catch (error) {
        console.error('Error fetching magazines:', error);
        // Handle error here
      }
    };

    fetchMagazines();
  }, []);

  console.log('Magazines:', magazines); // Log magazines data before passing to Accordion component

  return (
    <div data-section className="Mags_page" id="magz">
      <M_header id="header" />
      <Accordion magazines={magazines} />
      <Footer />
    </div>
  );
};

export default Magz;

