import React, { useState, useEffect ,useRef } from 'react'
import './Nav.css'
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';


const Navbar = () => {

  // -------------Sticky nav-----------

  const [navPosition, setNavPosition] = useState('sticky');


  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY > 250 ? 'scrolled' : 'sticky';
      setNavPosition(position);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

 
  // -------------Active nav-------------

  const [activeSection, setActiveSection] = useState(null);
  const sections = useRef([]);

  const handleScroll = () => {
    const pageYOffset = window.pageYOffset;
    let newActiveSection = null;

    sections.current.forEach((section) => {
      const sectionOffsetTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;

      if (pageYOffset >= sectionOffsetTop && pageYOffset 
      < sectionOffsetTop + sectionHeight) {
        newActiveSection = section.id;
      }
    });

    setActiveSection(newActiveSection);
  };

  useEffect(() => {
    sections.current = document.querySelectorAll('[data-section]');
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const activeStyle = {
    fontWeight: 'bold',
    color: '#D9A800',
    textDecoration: 'none',
    padding: '0.25rem calc(var(--ff-size) / 2.5)',
  };


  // -----------------Show and Hide------------

  const [isDivShown, setIsDivShown] = useState(false);

  const showDiv = () => {
    setIsDivShown(true);
  }

  const hideDiv = () => {
    setIsDivShown(false);
  }


  // --------------smooth scroll ----------------

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const scrollToSection = (sectionId) => {
    scroll.scrollTo(document.getElementById(sectionId).offsetTop);
  };

   // --------------smooth scroll + show and hide----------------
  const handleClickOne = () => {
    hideDiv();
    scrollToTop();
  };

  const handleClickTwo = () => {
    showDiv();
    scrollToTop();
  };


  return (

    <div className={`nav-wrapper ${navPosition}`} id="navbar">

      <div id="nav-logo">
            <img src="/Assets/10minwithstar_logo.svg" alt="" />
      </div>

      {/* Mobile nav placeholder */}
      <div className="nav-placeholder">
        <img src="/Assets/10Minlogo-black.png" alt="" />

        <div className="socials" id="mobile_socials">

            <a href="https://www.youtube.com/channel/UCNEwm_YhSyt9fQ5KaMr6J0Q" id="social-one" className="social" >
                <img src="/Assets/Socials/Youtube_BW.png" alt=""/>
            </a>

            <a href="https://instagram.com/10minutes_withthestar?igshid=YmMyMTA2M2Y=" id="social-two" className="social" >
            <img src="/Assets/Socials/Instagram_BW.png" alt=""/>
            </a>

            <a href="https://www.tiktok.com/@10minutes_withthestar" id="social-three" className="social" >
            <img src="/Assets/Socials/TikTock_BW.png" alt=""/>
            </a>

            <a href="https://www.facebook.com/10MWTS?mibextid=LQQJ4d" id="social-four" className="social" >
            <img src="/Assets/Socials/Facebook_BW.png" alt=""/>
            </a>

        </div>

      </div>

      <nav className={`nav ${navPosition}`}>
        {/* Navbar content here */}

          <div className="nav__slider"></div>

          <Link to="/"  smooth={true} duration={500} style={activeSection === 'landing' ? activeStyle : {}} className={activeSection === "landing" ? "active" : "nav__item" } onClick={handleClickOne}>Home</Link>

          {!isDivShown && isDivShown && 
          <a href='#magazine' style={activeSection === 'magazine' ? activeStyle : {}} className={activeSection === 'section2' ? 'active' : 'nav__item'}  onClick={scrollToSection.bind(null, 'magazine')}>Magazines</a>
           }

          {!isDivShown && isDivShown && 
          <a href='#articles' style={activeSection === 'articles' ? activeStyle : {}} className={activeSection === 'section3' ? 'active' : 'nav__item'} onClick={scrollToSection.bind(null, 'articles')}>Articles</a>

          }

          {!isDivShown && isDivShown && 
          <a href='#newsletter' style={activeSection === 'newsletter' ? activeStyle : {}} className={activeSection === 'section4' ? 'active' : 'nav__item'} onClick={scrollToSection.bind(null, 'newsletter')}>Newsletter </a> 
          }
          

          <div className="nav__item"> | </div>
            
            <Link to="/about" smooth={true} duration={500} style={activeSection === 'about' ? activeStyle : {}} className={activeSection === "about" ? "active" : "nav__item" } onClick={handleClickTwo}>About us</Link>
      
            {isDivShown && !isDivShown && 
               <a href='#services' smooth={true} duration={500} style={activeSection === 'services' ? activeStyle : {}} className={activeSection === "services" ? "active" : "nav__item" } onClick={scrollToSection.bind(null, 'services')}>Services</a>
            }

            {isDivShown && !isDivShown && 
              <a href='#contact_us' smooth={true} duration={500} style={activeSection === 'contact_us' ? activeStyle : {}} className={activeSection === "contact_us" ? "active" : "nav__item" } onClick={scrollToSection.bind(null, 'contact_us')}>Contact us</a>
            }

            {/* Mags and Blogs Nav  */}

            {/* {!isDivShown &&  */}
              <Link to="/magz" smooth={true} duration={500} style={activeSection === 'magz' ? activeStyle : {}} className={activeSection === "magz" ? "active" : "nav__item" } onClick={handleClickTwo.bind(null, 'magz')}>Magazines</Link>
            {/* }  */}

            {/* {!isDivShown &&  */}
              <Link to="/Blogs" smooth={true} duration={500} style={activeSection === 'blogs' ? activeStyle : {}} className={activeSection === "blogs" ? "active" : "nav__item" } onClick={handleClickTwo}>Blogs</Link>
             {/* }  */}

      </nav>


        <div className="socials">

            <a href="https://www.youtube.com/channel/UCNEwm_YhSyt9fQ5KaMr6J0Q" id="social-one" className="social" >
                <img src="/Assets/Socials/Youtube_BW.png" alt=""/>
            </a>

            <a href="https://instagram.com/10minutes_withthestar?igshid=YmMyMTA2M2Y=" id="social-two" className="social" >
            <img src="/Assets/Socials/Instagram_BW.png" alt=""/>
            </a>

            <a href="https://www.tiktok.com/@10minutes_withthestar" id="social-three" className="social" >
            <img src="/Assets/Socials/TikTock_BW.png" alt=""/>
            </a>

            <a href="https://www.facebook.com/10MWTS?mibextid=LQQJ4d" id="social-four" className="social" >
            <img src="/Assets/Socials/Facebook_BW.png" alt=""/>
            </a>

        </div>

    </div>
  );
};

export default Navbar



