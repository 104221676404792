// import React from 'react';
// import { Link } from 'react-router-dom';
// import BottomFooter from '../Home/Footer/BottomFooter';
// import HeaderAlone from '../Header/headerAlone';
// import './Signed.css';

// const Signedin = () => {
//   return (
//     <div className="Signedin">
//       <HeaderAlone/>
//       <div className="wrapper">
//         <img src='' alt='profile'/>
//         <p id='loggedin'>Logged in as 10min</p>
//         <h3>Internal use only</h3>
//         <div className="option-wrapper">
//           <Link to="/Createblog" className="options">
//             Create Blog
//           </Link>
//           <Link to="/Create-ad" className="options">
//             Create Ad
//           </Link>
//           <Link to="/Createmag" className="options">
//             Create Magazine
//           </Link>
//         </div>
//         <Link to="/Bloglogin">Log out </Link>
//         <Link to="/" id='backhome'> Back to home</Link>
//       </div>

//       {/* <BottomFooter/> */}
//     </div>
//   )
// }

// export default Signedin

import React from 'react';
import { Link } from 'react-router-dom';


import './Signed.css';
import { useAuth } from './AuthContext';
import HeaderAlone from '../Header/headerAlone';

const Signedin = ({ profilePicUrl, signUserOut }) => {
  const { user, logout } = useAuth();

  return (
    <div className="Signedin">
      <HeaderAlone/>
      <div className="wrapper">
      {profilePicUrl && <img src={profilePicUrl} alt="User Profile" />}
        {/* <img src='' alt='profile' /> */}
        <p id='loggedin'>Logged in as {user ? user.displayName : '10 MinutesWithTheStar'}</p>
        <h3>Internal use only</h3>
        <div className="option-wrapper">
          <Link to="/createblog" className="options">
            Create Blog
          </Link>
          <Link to="/create-ad" className="options">
            Create Ad
          </Link>
          <Link to="/createmag" className="options">
            Create Magazine
          </Link>
        </div>
        
        {/* <p onClick={signUserOut}>Log out </p> */}
       {/* <p onClick={signUserOut}> Back to home</p> */}
       <Link to="/"> Back to home</Link>
      </div>
    </div>
  );
};

export default Signedin;

