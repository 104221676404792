import React, { useState } from 'react';
import { auth, provider } from '../firebase-config';
import { signInWithPopup, signInWithEmailAndPassword, GoogleAuthProvider, updateProfile } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import './Bloglogin.css';
import HeaderAlone from '../../Header/headerAlone';
import BottomFooter from '../../Home/Footer/BottomFooter';

const Bloglogin = ({ setIsAuth, setUserProfile }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  let navigate = useNavigate();

  const signInWithGoogle = () => {
    signInWithPopup(auth, new GoogleAuthProvider())
      .then((result) => {
        const profilePicUrl = result.user.photoURL;
        localStorage.setItem('isAuth', true);
        localStorage.setItem('profilePicUrl', profilePicUrl);
        setIsAuth(true);
        setUserProfile(profilePicUrl);
        navigate('/Signedin');
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const signInWithEmailAndPasswordHandler = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        const profilePicUrl = result.user.photoURL;
        localStorage.setItem('isAuth', true);
        localStorage.setItem('profilePicUrl', profilePicUrl);
        setIsAuth(true);
        setUserProfile(profilePicUrl);
        navigate('/Signedin');
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="bloglogin-area">
      <HeaderAlone />
      <h1>SIGN IN</h1>
      <p>Sign in using your assigned google account to create and delete blogs</p>
      {/* <button className="login-with-google-btn" onClick={signInWithGoogle}>
        Sign in with Google
      </button> */}
      <div className="email-password-signin">
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <div className="password-input">
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span onClick={toggleShowPassword}>{showPassword ? 'Hide' : 'Show'}</span>
        </div>
        <button onClick={signInWithEmailAndPasswordHandler}>Sign in</button>
      </div>
      {error && <p className="error-message">{"Credentials invalid. Please sign in using your assigned credentials "}</p>}
      <p className="internal-text">Internal use only</p>
      <Link to="/"><p className="back-text">Back to home</p></Link>
    </div>
  );
};

export default Bloglogin;
