// Automatic.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../Blogs/firebase-config';
import { collection, getDocs } from 'firebase/firestore';
import './Mags_area.css';

function Automatic({ magazines }) {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);

  const handleMagazineClick = (magazine) => {
    if (magazine && magazine.id) {
      navigate(`/magazine-viewer/${magazine.id}`);
    } else {
      console.error('Invalid magazine data:', magazine);
      alert('Invalid magazine data. Unable to view magazine.');
    }
  };

   // Function to format date
   const formatDate = (seconds) => {
    const date = new Date(seconds * 1000);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).replace(/ /g, ' '); // Add space after month
  };

  useEffect(() => {
    const fetchMagazines = async () => {
      try {
        const magazineCollectionRef = collection(db, 'Magazines');
        const snapshot = await getDocs(magazineCollectionRef);
        const magazineList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log('Magazines fetched:', magazineList);
        setItems(magazineList.map(magazine => ({
          title: magazine.title,
          content: (
            <div className="year">
              {magazine.issues.map((issue, idx) => (
                <div
                  className="mag_one mag"
                  key={idx}
                  onClick={() => navigate(`/magazine-viewer/${issue.id}`)}
                >
                  <img src={issue.coverImage} alt={issue.title} />
                  <h4>{issue.title}</h4>
                  <h5>{formatDate(magazine.timestamp.seconds)}</h5>
                </div>
              ))}
            </div>
          ),
          isOpen: true,
          imageUrl: magazine.coverImage,
        })));
      } catch (error) {
        console.error('Error fetching magazines:', error);
      }
    };

    fetchMagazines();
  }, [navigate]);

  const toggleAccordion = (index) => {
    const updatedItems = items.map((item, i) => ({
      ...item,
      isOpen: i === index ? !item.isOpen : false,
    }));
    setItems(updatedItems);
  };

  return (
    <div className="autoMag">
      <h2>10 minutes with the star magazines</h2>

      <h3>2024</h3>
      <div className="magazineTiles">
        {magazines && magazines.map((magazine) => (
          <div className="magazineTile mag_one mag" key={magazine.id} onClick={() => handleMagazineClick(magazine)}>
            <img src={magazine.pages && magazine.pages.length > 0 ? magazine.pages[0] : ''} alt={magazine.title} />
            <h4>{magazine.title}</h4>
            {/* <h5>{formatDate(magazine.date)}</h5> */}
            <h5>{formatDate(magazine.timestamp.seconds)}</h5>
          </div>
        ))}
        {/* Additional static magazines */}
        <div className="mag_one mag" onClick={() => window.location.href = 'Turn/Magz/mag-EGG-ZENANDE/index.html'}>
          <img src="Assets/MagCovers/MAGCover-EGG-Zenande.jpg" alt='EGG-Gll'/>
          <h4>ZENANDE MFENYANA PAPAGHOST</h4>
          <h5>June</h5>
        </div>
        <div className="mag_one mag" onClick={() => window.location.href = 'Turn/Magz/mag-EGG-ALPHI-SIPHO/index.html'}>
          <img src="Assets/MagCovers/MAGCover-EGG-ALPHISIPHO.jpg" alt='EGG-Gll'/>
          <h4>ALPHI SIPHO</h4>
          <h5>APRIL</h5>
        </div>
        <div className="mag_one mag" onClick={() => window.location.href = 'Turn/Magz/mag-EGG-GLL/index.html'}>
          <img src="Assets/MagCovers/MAGCover-EGG-GLL.jpg" alt='EGG-Gll'/>
          <h4>GIVEN ZULU LADY ZAMA LERATO MOKOKA</h4>
          <h5>March</h5>
        </div>
        <div className="mag_one mag" onClick={() => window.location.href = 'Turn/Magz/mag-EGG/index.html'}>
          <img src="Assets/MagCovers/MAGCover-EGG.jpg" alt='MAGCover-EGG'/>
          <h4>DONALD BU & YALLUNDER</h4>
          <h5>February</h5>
        </div>
        <div className="mag_one mag" onClick={() => window.location.href = 'Turn/Magz/mag-DEC&JAN/index.html'}>
          <img src="Assets/MagCovers/MAGCover-DEC&JAN.jpg" alt='MAGCover-DEC&JAN'/>
          <h4>DEC & JAN</h4>
          <h5>January</h5>
        </div>
      </div>

      <hr/>

      <h3>2023</h3>

      <div className="magazineTiles">
        <div className="mag_one mag" onClick={() => window.location.href = 'Turn/Magz/mag-IGAGU/index.html'}>
          <img src="Assets/MagCovers/MAGCover-IGAGU.jpg" alt='MAGCover-IGAGU'/>
          <h4>IGAGU</h4>
          <h5>September</h5>
        </div>
        <div className="mag_two mag" onClick={() => window.location.href = 'Turn/Magz/mag-WOMEN/index.html'}>
          <img src="Assets/MagCovers/MAGCover-Women.jpg" alt='Empowering women'/>
          <h4>Women</h4>
          <h5>August</h5>
        </div>
        <div className="mag_three mag" onClick={() => window.location.href = 'Turn/Magz/mag-KLY/index.html'}>
          <img src="Assets/MagCovers/MAGCover-KLY.jpg" alt='The return of KLY'/>
          <h4>KLY</h4>
          <h5>July</h5>
        </div>
        <div className="mag_four mag" onClick={() => window.location.href = 'Turn/Magz/mag-TBO-Touch/index.html'}>
          <img src="Assets/MagCovers/MAGCover-TBO-Touch.jpg" alt='TBO Touch'/>
          <h4>TBO Touch</h4>
          <h5>June</h5>
        </div>
      </div>

      <hr/>
      <h3>2022</h3>
      <div className="magazineTiles">
        <div className="mag_one mag" onClick={() => window.location.href = 'Turn/Magz/mag-Murmba-Pitch/index.html'}>
          <img src="Assets/MagCovers/MAGCover-MPITCH.jpg" alt='Murumba Pitch'/>
          <h4>Murumba Pitch</h4>
          <h5>November</h5>
        </div>
        <div className="mag_two mag" onClick={() => window.location.href = 'Turn/Magz/mag-FLVME/index.html'}>
          <img src="Assets/MagCovers/MAGCover-FLVME.jpg" alt='FLVME'/>
          <h4>FLVME</h4>
          <h5>October</h5>
        </div>
        <div className="mag_three mag" onClick={() => window.location.href = 'Turn/Magz/mag-Zamani-Ndimande/index.html'}>
          <img src="Assets/MagCovers/MAGCover1.png" alt='Z Ndimande'/>
          <h4>Z Ndimande</h4>
          <h5>September</h5>
        </div>
        <div className="mag_four mag" onClick={() => window.location.href = 'Turn/Magz/mag-Thato-MBHA/index.html'}>
          <img src="Assets/MagCovers/MAGCover-TTMBHA.jpg" alt='Thato MBHA Pitch'/>
          <h4>TTMBHA</h4>
          <h5>August</h5>
        </div>
      </div>
    </div>
  );
}

export default Automatic;


