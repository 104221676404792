import React from 'react'
import './Services.css'


function Services() {

    const hoverVideo = (e) => {
      e.target.play();
    }
  
    const hideVideo = (e) => {
      e.target.pause();
    }

  return (
    <div data-section className="services" id="services">

            <div className="col col-4 " id="service-one">
              <video src="Assets/Videos/Dancing.mp4" type="video/mp4" loop preload="yes" onMouseEnter={hoverVideo} onMouseLeave={hideVideo} ></video>
              <h3>ARTIST MANAGEMENT</h3>
              <p>As an artist manager, our role involves representing and guiding our clients throughout their careers. This includes managing their finances, bookings, promotions, and overall professional development, with the goal of helping them achieve their artistic and personal goals.</p>
            </div>

            <div className="col col-4" id="service-two">
            <video src="Assets/Videos/Instrument.mp4" type="video/mp4" loop preload="yes" onMouseEnter={hoverVideo} onMouseLeave={hideVideo} ></video>
              <h3>TALENT DEVELOPMENT</h3>
              <p>As a talent manager, our focus is on identifying and developing individuals with exceptional abilities, helping them to maximize their potential and achieve their career goals. This involves a range of activities, including recruitment, training, coaching, and career planning, all aimed at creating opportunities for our clients to succeed and thrive in their chosen field.</p>
            </div>
            
            <div className="col col-4" id="service-three">
            <video src="Assets/Videos/Handshake.mp4" type="video/mp4" loop preload="yes" onMouseEnter={hoverVideo} onMouseLeave={hideVideo} ></video>
              <h3>Public Relations</h3>
              <p>As a public relations professional, our role is to manage and shape the public image of our clients or organization. This involves developing and executing strategies to communicate key messages, building and maintaining relationships with stakeholders, managing crises, and creating opportunities for positive media coverage, all with the goal of enhancing reputation and building trust with the public.</p>
            </div>

            <div className="col col-4" id="service-four">
            <video src="Assets/Videos/Camera.mp4" type="video/mp4" loop preload="yes" onMouseEnter={hoverVideo} onMouseLeave={hideVideo} ></video>
              <h3>CONTENT CREATION</h3>
              <p>As a content creator, our focus is on producing engaging and informative material across a variety of mediums, such as written, visual, and audio content. This involves researching, writing, editing, and designing content that resonates with the target audience, while also ensuring that it aligns with the brand's voice and messaging. The goal is to create content that captures attention, inspires action, and ultimately drives engagement and growth.</p>
            </div>

    </div>
  )
}

export default Services