import React, { useState, useEffect } from 'react';
import { addDoc, collection, getDocs, deleteDoc, doc, serverTimestamp, updateDoc, increment, onSnapshot } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL ,deleteObject  } from 'firebase/storage';
import Navbar from '../../Header/Navbar/Nav';
import BottomFooter from '../../Home/Footer/BottomFooter';
import { auth, db, storage } from '../../Blogs/firebase-config';
import './Mags_area.css';

const CreateMagazine = ({ isAuth, addMagazineImage, filteredMagazines, addMagazine }) => {
  const [title, setTitle] = useState('');
  const [pages, setPages] = useState([]);
  const [previewPages, setPreviewPages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [magazines, setMagazines] = useState([]);
  const [selectedMagazine, setSelectedMagazine] = useState(null); // State for selected magazine
  const [currentPageIndex, setCurrentPageIndex] = useState(0); // State for current page in preview
  const magazineCollectionRef = collection(db, 'Magazines');
  const storageRef = ref(storage, 'Magazines');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMagazines = async () => {
      try {
        const data = await getDocs(magazineCollectionRef);
        setMagazines(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      } catch (error) {
        console.error('Error fetching magazines:', error);
      }
    };

    fetchMagazines();
  }, []);


  const handlePageChange = (event) => {
    const files = Array.from(event.target.files);
    const newPreviewPages = [];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        newPreviewPages.push(e.target.result);
        setPreviewPages([...newPreviewPages]);
      };
      reader.readAsDataURL(file);
    });

    setPages(files);
  };

  const uploadMagazine = async () => {
    setUploading(true);
    try {
      const uploadedPages = [];
      const magazineFolderId = Math.random().toString(36).substring(2);
      const magazineFolderRef = ref(storageRef, `Magazines/${magazineFolderId}`);

      const placeholderContent = new Blob(['placeholder']);
      await uploadBytes(ref(magazineFolderRef, '.placeholder'), placeholderContent);

      for (const page of pages) {
        const pageRef = ref(magazineFolderRef, page.name);
        await uploadBytes(pageRef, page);
        const downloadURL = await getDownloadURL(pageRef);
        uploadedPages.push(downloadURL);
      }
      return uploadedPages;
    } catch (error) {
      console.error('Error uploading magazine:', error);
      return [];
    } finally {
      setUploading(false);
    }
  };

  const createMagazine = async () => {
    if (!title.trim() || pages.length === 0) return;

    const uploadedPages = await uploadMagazine();
    if (uploadedPages.length === 0) return;

    try {
      const docRef = await addDoc(magazineCollectionRef, {
        title,
        pages: uploadedPages,
        author: {
          name: auth.currentUser.displayName,
          id: auth.currentUser.uid,
        },
        timestamp: serverTimestamp(),
        views: 0, // Initialize views
      });

      setMagazines([...magazines, { id: docRef.id, title, pages: uploadedPages, views: 0 }]);
      navigate(`/Magazine-viewer/${docRef.id}`);
    } catch (error) {
      console.error('Error creating magazine:', error);
    }
  };


  const deleteMagazine = async (id) => {
    try {
      // Find the magazine to be deleted
      const magazineToDelete = magazines.find(mag => mag.id === id);
  
      if (!magazineToDelete) {
        console.error('Magazine not found:', id);
        return;
      }
  
      // Delete each page file from Firebase Storage
      for (const pageUrl of magazineToDelete.pages) {
        const pageRef = ref(storage, pageUrl);
        await deleteObject(pageRef);
      }
  
      // Delete the magazine document from Firestore
      await deleteDoc(doc(db, 'Magazines', id));
  
      // Remove the deleted magazine from the local state
      setMagazines(magazines.filter(mag => mag.id !== id));
    } catch (error) {
      console.error('Error deleting magazine:', error);
    }
  };
  

  const handleMagazineClick = (magazine) => {
    setSelectedMagazine(magazine);
    setCurrentPageIndex(0); // Reset to the first page when a new magazine is selected
  };

  const nextPage = () => {
    if (currentPageIndex < selectedMagazine.pages.length - 1) {
      setCurrentPageIndex(currentPageIndex + 1);
    }
  };

  const prevPage = () => {
    if (currentPageIndex > 0) {
      setCurrentPageIndex(currentPageIndex - 1);
    }
  };

  return (
    <div className="create-magazine-area">
      <Navbar />
      <div className="cm-container">
        <div className="input-group title">
          <label>Title</label>
          <input
            placeholder="Write your magazine title here..."
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />

          <button
              onClick={createMagazine}
              disabled={!title.trim() || pages.length === 0 || uploading}
              style={{ cursor: uploading ? 'not-allowed' : 'pointer' }}
            >
              {uploading ? 'Posting Magazine...' : 'Post Magazine'}
          </button>

        </div>

        <div className="magazine-manager">
          <div className="cloud">

            <div className="upload-wrapper">
              <div className="input-group pages import-button">
                <input type="file" accept="image/*" multiple onChange={handlePageChange} />
                
              </div>
              <label>Import magazine (pages)</label>
              <p>Please make sure the pages are named in sequence (e.g. 1.jpeg , 2.jpeg)</p>
            </div>
          
            <h4>2024</h4>
            <hr/>
            <div className="posted-magazine">
              {magazines.map((magazine) => (
                <div key={magazine.id} className="magazine-item" onClick={() => handleMagazineClick(magazine)}>
                  <img src={magazine.pages[0]} alt="Cover" />
                  <h4>{magazine.title}</h4>
                  <p>{new Date(magazine.timestamp.seconds * 1000).toLocaleDateString()}</p>
                  <p>{magazine.views} view{magazine.views !== 1 ? 's' : ''}</p>
                  <button onClick={(e) => { e.stopPropagation(); deleteMagazine(magazine.id); }}>Delete</button>
                </div>
              ))}
            </div>
          </div>

          <div className="magazine-preview">
            <h3>Magazine Preview</h3>
            {selectedMagazine ? (
              <>
                <div className="navigation">
                  <button onClick={prevPage} disabled={currentPageIndex === 0} id="left-btn"> &#x27A4;</button>
                  <button onClick={nextPage} disabled={currentPageIndex === selectedMagazine.pages.length - 1} id="right-btn">&#x27A4;</button>
                  <div className="preview-container">
                    {previewPages.map((preview, index) => (
                      <img key={index} src={preview} alt={`Page ${index + 0}`} className="preview-image" />
                    ))}

                    
                    <img
                      src={selectedMagazine.pages[currentPageIndex]}
                      alt={`Page ${currentPageIndex + 1}`}
                      className="preview-image"
                    />

                    <h2>{selectedMagazine.title}</h2>
                    
                  </div>
                  
                </div>
               
              </>
            ) : (
              <p>Please select a magazine to preview</p>
            )}

            {/* <div className="title-preview">
              <label>Title Preview:</label>
              <input type="text" value={title } readOnly />
            </div> */}

          </div>
        </div>

      </div>
      {/* <BottomFooter /> */}
    </div>
  );
};

export default CreateMagazine;

