import React from 'react'
import './About.css'
import BottomFooter from '../../Home/Footer/BottomFooter'
import Footer from '../../Home/Footer/Footer'

const About_us = () => {
  return (
    <div data-section className="about_us" id="about">
        {/* <iframe src="https://www.youtube.com/embed/dv65QOwPsl8?autoplay=1&mute=1&controls=0&loop=1"></iframe>   */}
        {/* <iframe></iframe> */}
        <div className="left_block">
                <h3>About us</h3>
                <p>
                A CREATIVE ONLINE SHOW 
                <br/>
                The Show seeks to get up close with African most popular and influential music and creatives through one-on-one interviews with them.
                <br/>
                The objective is for their fans to see beyond the brand and connect to its the human whose journey has brought them success and stardom.
                <br/>
                10Minutes with the star also features up and coming Artist, whose nascent musical journey, the show hopes to track and give the viewers a sense of having grown with the star.
                <br/>
                The purpose of the show is to give young up and coming Artist, entrepreneurs, Musicians and individuals a platform to market not only themselves but their craft.
                </p>
            </div>

            <div className="right_block">

                <div className="profile_image">
                    <img src="Assets/10minwithstar_logo.svg" alt="" />
                </div>
            </div>

            {/* <BottomFooter/> */}
           {/* <Footer/> */}
    </div>
  )
}

export default About_us