import './BlogsAccordion.css'

import React, { useState, useEffect } from 'react';
import PostFromPost from './BlogPosts/PostFromPost';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase-config';

function AccordionItem({ title, content, isOpen, toggleAccordion }) {
  return (
    <div className={`accordion-item-2 ${isOpen ? 'open' : ''}`}>
      <div className="accordion-header" onClick={toggleAccordion}>
        <h3>{title}</h3>
      </div>
      {isOpen && <div className="accordion-content">{content}</div>}
    </div>
  );
}

function AccordionB({ isAuth }) {
  const [months, setMonths] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const posts = await fetchPostsFromFirebase();
        const groupedPosts = groupPostsByYear(posts, currentYear);
        setMonths(groupedPosts);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchData();
  }, [currentYear]);

  const fetchPostsFromFirebase = async () => {
    const snapshot = await getDocs(collection(db, 'posts'));
    return snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  };

  const groupPostsByYear = (posts, year) => {
    const groupedPosts = {
      title: year.toString(),
      posts: [],
      isOpen: true,
    };

    posts.forEach(post => {
      const postDate = new Date(post.timestamp.seconds * 1000);
      const postYear = postDate.getFullYear();

      if (postYear === year) {
        groupedPosts.posts.push(post);
      }
    });

    // Sort posts within the year by timestamp (from newest to oldest)
    groupedPosts.posts.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);

    return [groupedPosts];
  };

  const toggleAccordion = () => {
    // Toggle is not needed as the current year is always open
  };

  return (
    <div className="accordion">
      {loading ? (
        <div>Loading...</div>
      ) : (
        months.map((month, index) => (
          <AccordionItem
            key={index}
            title={month.title}
            content={
              <div className="accordion-content">
                <PostFromPost isAuth={isAuth} posts={month.posts} />
              </div>
            }
            isOpen={month.isOpen}
            toggleAccordion={toggleAccordion}
          />
        ))
      )}
    </div>
  );
}

export default AccordionB;


// import './BlogsAccordion.css';
// import React, { useState, useEffect } from 'react';
// import PostFromPost from './BlogPosts/PostFromPost';
// import { collection, getDocs } from 'firebase/firestore';
// import { db } from '../../firebase-config';

// function AccordionItem({ title, content, isOpen }) {
//   return (
//     <div className={`accordion-item-2 ${isOpen ? 'open' : ''}`}>
//       <div className="accordion-header">
//         <h3>{title}</h3>
//       </div>
//       {isOpen && <div className="accordion-content">{content}</div>}
//     </div>
//   );
// }

// function AccordionB({ isAuth }) {
//   const [accordionItems, setAccordionItems] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const posts = await fetchPostsFromFirebase();
//         const sortedPosts = sortPosts(posts);
//         const accordionItems = createAccordionItems(sortedPosts);
//         setAccordionItems(accordionItems);
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching posts:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   const fetchPostsFromFirebase = async () => {
//     const snapshot = await getDocs(collection(db, 'posts'));
//     return snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
//   };

//   const sortPosts = posts => {
//     return posts.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);
//   };

//   const createAccordionItems = posts => {
//     const accordionItems = {};

//     posts.forEach(post => {
//       const postDate = new Date(post.timestamp.seconds * 1000);
//       const year = postDate.getFullYear();
//       const month = postDate.getMonth() + 1; // Adding 1 to get month index starting from 1

//       const title = `${month}/${year}`;

//       if (!accordionItems[title]) {
//         accordionItems[title] = [];
//       }

//       accordionItems[title].push(post);
//     });

//     return accordionItems;
//   };

//   return (
//     <div className="accordion">
//       {loading ? (
//         <div>Loading...</div>
//       ) : (
//         Object.keys(accordionItems).map(title => (
//           <AccordionItem
//             key={title}
//             title={title}
//             content={<PostFromPost isAuth={isAuth} posts={accordionItems[title]} />}
//             isOpen={true} // Always open
//           />
//         ))
//       )}
//     </div>
//   );
// }

// export default AccordionB;

