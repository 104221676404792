import React from 'react'
import './Landing.css';

const Landing = () => {
  return (
    <div data-section className="landing" id="landing">
      {/* <iframe src="https://www.youtube.com/embed/TCnjnpDjyF4?autoplay=0&mute=1controls=0"></iframe>  */}
      {/* <video className="desktop_clip" src="Assets/Videos/Promo.mp4" type="video/mp4" autoplay loop controls="1" ></video>
      <video className="mobile_clip" src="Assets/Videos/Promo_mobile.mp4" type="video/mov" autoplay loop controls="1" ></video> */}
      <img className="desktop_landing" src="Assets/Landing/Desktop.jpg" alt="Home for creatives image"/>
      <img className="mobile_landing" src="Assets/Landing/Mobile.jpg" alt="Home for creatives image"/>
    </div>
  )
}

export default Landing;