import Header from "../../Header/header"
import Footer from "../../Home/Footer/Footer"
import AccordionB from "./Accordion/Accordion"
import './Blogs.css'
import Bl_Header from "./BlogHeader/Bl_Header"

const Blogs = () => {

  return (
    <div className="Blogslanding">
      <Header/>
      <Bl_Header/>
      <AccordionB/>
      <Footer/>
    </div>
  )
}

export default Blogs