import React, { useState, useEffect, useRef } from 'react';
import PageFlip from 'react-pageflip';
import './AutomagStyle.css';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../../Blogs/firebase-config';
import Navbar from '../../Header/Navbar/Nav';
import Footer from '../../Home/Footer/Footer';

const MagazineViewer = () => {
    const { magazineId } = useParams();
    const navigate = useNavigate();
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(true); // State to handle loading
    const pageFlipRef = useRef(null);

    // This ref ensures that the view count is only incremented once
  const hasIncrementedRef = useRef(false);

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const magazineRef = doc(db, 'Magazines', magazineId);
        const magazineDoc = await getDoc(magazineRef);

        if (magazineDoc.exists()) {
          const magazineData = magazineDoc.data();
          setPages(magazineData.pages);

          // Only increment the view count if it hasn't been incremented yet
          if (!hasIncrementedRef.current) {
            await updateDoc(magazineRef, {
              views: increment(1),
            });
            hasIncrementedRef.current = true;
          }

          setLoading(false);
        } else {
          console.error('No such document!');
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching magazine pages:', error);
        setLoading(false);
      }
    };

    fetchPages();
  }, [magazineId]);

    const handleNext = () => {
        pageFlipRef.current.pageFlip().flipNext();
    };

    const handlePrev = () => {
        pageFlipRef.current.pageFlip().flipPrev();
    };

    const handleBack = () => {
        navigate('/magz');
    };

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Check out this magazine!',
                text: 'Take a look at this magazine:',
                url: window.location.href,
            }).catch((error) => console.error('Error sharing:', error));
        } else {
            alert('Sharing is not supported on this browser.');
        }
    };

    

    // Scroll to the top when postId changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="MagazineViewer">
                <div className="navHandle">
                    <Navbar />
                </div>
                
                <div className="magnav">
                    <button id="back" onClick={handleBack}>
                        Back
                    </button>
                    <button id="share" onClick={handleShare}>
                        Share
                    </button>
                </div>
                {loading ? (
                    <p>Loading...</p>
                ) : pages.length > 0 ? (
                    <>
                        <button className="arrow left-arrow" onClick={handlePrev}>◀</button>
                        <PageFlip width={600} height={800} showCover={true} ref={pageFlipRef}>
                            <div className="page cover-page">
                                <div className="page-inner">
                                    <img src={pages[0]} alt="Cover Page" />
                                </div>
                            </div>
                            
                            {pages.slice(1, pages.length - 1).map((pageUrl, index) => (
                                <div key={index} className="page">
                                    <div className="page-inner">
                                        <img src={pageUrl} alt={`Page ${index + 2}`} />
                                    </div>
                                </div>
                            ))}
                            
                            <div className="page cover-page">
                                <div className="page-inner">
                                    <img src={pages[pages.length - 1]} alt="Back Cover Page" />
                                </div>
                            </div>
                        </PageFlip>
                        <button className="arrow right-arrow" onClick={handleNext}>▶</button>
                    </>
                ) : (
                    <p>No pages found for this magazine.</p>
                )}
            </div>
            <Footer />

            </>
        
            );
        
        };
        
        export default MagazineViewer;
